import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteIcon from '@mui/icons-material/Delete';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import { blue } from "@mui/material/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import noRegistered from "../../Images/empty_states/no-registered.png";
import { EmptyState } from "../../components/EmptyStates/EmptyState";
import Spinner from "../../components/Spinner";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from "../../components/WarningModal";

const cookies = new Cookies();

const UnRegisteredUser = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [skills, setSkills] = useState("");
  const [totalExperience, setTotalExperience] = useState("");
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage , setCurrentPage] = useState(1)
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // Store the selected user ID
  const [error, setError] = useState(false);

  // Function to get data
  async function getData(page = 1, clearFilters = false) {
    setLoading(true);
    await axios
      .get("hri_admin/bulk-resume-list", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
          skills: clearFilters ? "" : skills,
          total_years_experience: clearFilters ? "" : totalExperience,
          created_at: clearFilters ? "" : date,
        },
      })
      .then((resp) => {
        setUserList(resp.data.results);
        setLoading(false);
        setTotalPages(Math.ceil(resp.data.count / 10));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // Function to clear filters
  const handleClear = () => {
    setSkills("");
    setTotalExperience("");
    setDate(null);
    getData(1, true);
    setCurrentPage(1)
  };

  // Function to search with filters
  const handleSearch = () => {
    getData(1);
    setCurrentPage(1)
  };

  // Function to handle delete
  const handleDelete = async () => {
    try {
      await axios.delete(`hri_admin/bulkresume-delete/${selectedUserId}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      });

      toast.success('Resume deleted successfully', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#1ab394", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#BAE8DE" },
      });

      setUserList(userList.filter((user) => user.id !== selectedUserId));
      setOpenModal(false); // Close modal after deletion
    } catch (error) {
      toast.error('Error deleting user!');
      setOpenModal(false); // Close modal on error
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Function to open the modal and set the user ID
  const openDeleteModal = (userId) => {
    setSelectedUserId(userId); // Store the user ID to be deleted
    setOpenModal(true); // Open modal
  };

  return (
        <Container maxWidth="lg" sx={{ margin: "3rem auto" }}>
          <ToastContainer position="top-right" autoClose={3000} />
      
          {/* Search Filters Section */}
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Skills"
                variant="outlined"
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Total Experience"
                variant="outlined"
                value={totalExperience}
                onChange={(e) => setTotalExperience(e.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setError(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      helperText={error ? "Date is required" : ""}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
      
          {/* Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button variant="outlined" color="secondary" onClick={handleClear}>
              Clear
            </Button>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Box>
      
          {/* Show spinner when loading */}
          {loading && <Spinner />}
      
          {/* Table or EmptyState */}
          {!loading && userList.length > 0 ? (
            <>
              <TableContainer component={Paper} elevation={2} sx={{ mt: 4 }}>
                <Table>
                  <TableHead sx={{ backgroundColor: blue[700] }}>
                    <TableRow>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>Applicants</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>Resume</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>Contact</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>Details</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList?.map((data) => (
                      <TableRow key={data.id} hover>
                        <TableCell>{data.name ? data.name : "NA"}</TableCell>
                        <TableCell>
                          <a href={data.resume} target="_blank" rel="noreferrer">
                            {data.resume_name ? data.resume_name : "NA"}
                          </a>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" gap={2}>
                            <Tooltip title={data.contact_no ? data.contact_no : "NA"}>
                              <IconButton>
                                <PhoneIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={data.email ? data.email : "NA"}>
                              <IconButton>
                                <MailOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              navigate("/user-management/unregistered-user/details/personal");
                              localStorage.setItem("Un-registered User", data.id);
                            }}
                          >
                            Details
                          </Button>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => openDeleteModal(data.id)} // Open the modal
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
      
              {/* Warning Modal */}
              <WarningModal
                open={openModal}
                close={() => setOpenModal(false)}
                title="Delete Resume?"
                content="Are you sure you want to delete this resume? This action cannot be undone."
                onclickfunction={handleDelete} // Pass handleDelete to execute on modal confirmation
                cancelText="No"
                agreeText="Yes"
              />
      
              {/* Pagination */}
              <Box mt={4} mb={6} display="flex" justifyContent="center" sx={{ paddingBottom: '2rem' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(_, page) => {
                        getData(page);
                        setCurrentPage(page);
                        }}
                        color="primary"
                    />
                </Box>


            </>
          ) : (
            !loading && (
              <EmptyState
                img={noRegistered}
                title="No Registered User Found"
                message="There are no users matching the current criteria. Try adjusting the filters or adding new users."
              />
            )
          )}
        </Container>
      );
      
};

export default UnRegisteredUser;
